import React from 'react'
// import 'antd/dist/antd.css';
import { ConfigProvider, Layout } from 'antd';
import HeaderItem from "../Header"
import FooterItem from "../Footer"

import "./layout.less"
const { Header, Footer, Content } = Layout;

export default function LayoutPage({children}) {
  // console.log("Layoutpage:location: ", window.location)
  return (
    <>
      {/* <ConfigProvider componentSize= "small"> */}
        <Layout justify="center" className="site-layout" >
          <HeaderItem/>
          <Content className='site-content'>{ children }</Content>
          <FooterItem/>
        </Layout>
      {/* </ConfigProvider> */}
    </>
  )
}