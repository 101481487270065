import React, {useState} from 'react'
import { Affix, Layout, Row, Col, Image, Menu, Typography, Button, Drawer } from 'antd';
import {Link} from 'gatsby'
import logoPic from '../../images/logo-dark.png'
import { HomeOutlined, BlockOutlined, DollarOutlined, LoginOutlined, FormOutlined, CopyOutlined, LikeOutlined, MenuOutlined} from '@ant-design/icons';
import LogoDark from '../../images/logo-dark.png'
import LogoWhite from '../../images/logo-white.png'
import './header.less'

const { Content } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

export default function Topmenu() {
  const [isAffixed, SetIsAffixed] = useState(false)
  const [current, setCurrent] = useState('mail')
  const [visible, setVisible] = useState(false)

  const handleClick = e => {
    console.log('click ', e);
    setCurrent(e.key)
  };

  return (
    <Affix onChange={state => SetIsAffixed(state)}>
      <Content className="site-header-root" style={isAffixed ? {color:"black", background: "white"} : {color:"white", background: "#0d47a1"}}>
        <Row className='site-header'>
          <a href="/" style={{alignItems: 'center', display: "flex"}}>
            <Image
              className='logo-image'
              src={isAffixed ? LogoDark : LogoWhite}
              preview={false}
            />
          </a>
          <Row xs={24} md={12} style={{alignItems: "center"}}>
            <div className={ isAffixed ? "bigMenu affixed" : "bigMenu"}>
                {/* <Link className="menuItem" href="/finances">Home</Link>
                <Link className="menuItem" href="/sante">Features</Link> */}
                <Button type="text" className="menuItem" href="/" icon={<HomeOutlined />}>Home</Button>
                <Button type="text" className="menuItem" href="/features" icon={<BlockOutlined />}>Features</Button>
                <Button type="text" className="menuItem" href="/pricing" icon={<DollarOutlined />}>Pricing</Button>
                <Button type="text" className="menuItem" href="/about-us" icon={<LikeOutlined />}>Use Cases</Button>
                <Button type="text" className="menuItem" href="/articles" icon={<CopyOutlined />}>Articles</Button>
                <Button type="text" className="menuItem" href="https://console.fieldnext.io" target="_blank" icon={<LoginOutlined />}>Login</Button>
            </div>
            <Button type={isAffixed ? "primary" : "secondary"} className={isAffixed ? "btnContact affixed" : "btnContact"} href="/contact" icon={<FormOutlined />}>Contact Us</Button>
            <Button className="menuBtn" type="primary" icon={<MenuOutlined />} onClick={()=>setVisible(true)}/>
            <Drawer title={<Image width={150} src={logoPic} alt="logo" />} placement="right" onClose={()=>setVisible(false)} visible={visible}>
              <div style={{display:'flex', flexDirection:"column"}}>
                <Button className="drawer-item" type="text" href="/" icon={<HomeOutlined />}>Home</Button>
                <Button className="drawer-item" type="text" href="/features" icon={<BlockOutlined />}>Features</Button>
                <Button className="drawer-item" type="text" href="/pricing" icon={<DollarOutlined />}>Pricing</Button>
                <Button className="drawer-item" type="text" href="/about-us" icon={<LikeOutlined />}>Use Cases</Button>
                <Button className="drawer-item" type="text" href="/articles" icon={<CopyOutlined />}>Articles</Button>               
              </div>
            </Drawer>
          </Row>
        </Row>
      </Content>        
    </Affix>
  );
}