import React from 'react'
import { Layout, Row, Col, Image, Divider } from 'antd';
import { Link } from 'gatsby'
import { PhoneTwoTone, MailTwoTone, getTwoToneColor, setTwoToneColor } from '@ant-design/icons';
import LogoDark from '../../images/logo-dark.png'

import "./footer.less"
const { Content } = Layout;

const contactItem = (icon, detail, type) => {
  return (
    <div className="contact-item">
      {icon}
      <div style={{ marginLeft: 8 }}>{detail}</div>
    </div>
  )
}

export default function FooterItem() {
  setTwoToneColor('#0d47a1');

  return (
    <Content className="site-footer-root" >
      <Col className='site-footer'>
        <Row className='topRow'>
          <div>
            <a href="/">
              <Image
                width={200}
                src={LogoDark}
                preview={false}
              // style={{marginBottom: 16}}
              />
            </a>
            {/* <div className='company-address'>427, Beside Belpatra Apartment, Bal Vihar Greens, Sonari, Jamshedpur, India 831011</div> */}
            {/* <div style={{width: 400}}><b>USA:</b> 7359 Water Oaks Dr. Westbloomfield, MI 48324</div> */}
          </div>
          <div className='contacts'>
            {contactItem(<PhoneTwoTone style={{ fontSize: 24, transform: "rotateY(180deg)" }} />, <Link href="tel:+918178972150" ><b>India:</b> +91 8178972150</Link>)}
            {/* {contactItem(<PhoneTwoTone style={{ fontSize: 24, transform: "rotateY(180deg)" }} />, <Link href="tel:+13139205235"><b>USA:</b> +1 (313) 920-5235</Link>)} */}
            {contactItem(<MailTwoTone style={{ fontSize: 24 }} />, <Link href="mailto:hello@fieldnext.io">hello@fieldnext.io</Link>)}
          </div>
        </Row>
        <Divider />
        <Row className='bottomRow'>
          <div>© 2022, DotNext Technologies Pvt. Ltd.</div>
          <div className='legel-container'>
            <Link className="terms" to="/terms">Terms of use</Link>
            <Link className="privacy-policy" to="/privacy">Privacy policy</Link>
          </div>
        </Row>
      </Col>
    </Content>
  )
}
